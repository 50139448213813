import styled from 'styled-components';
import theme from '../../config/theme';

export const Container = styled(theme.components.containers.main)`
  justify-content: center;
  align-content: center;
`

export const CenterContainer = styled.div`
  height: 301px;
  width: 100%;
  background: ${ theme.colors.white };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

export const Title = styled(theme.components.titles.hero)`
  font-size: 82px;
  line-height: 102px;
  
   @media screen and (max-width: ${ theme.breakpoints.mobileLarge }) {
      font-size: 50px;
      line-height: 62px;
   }
`

export const Description = styled(theme.components.descriptions.hero)`
  font-size: 30px;
  line-height: 37px;
  
   @media screen and (max-width: ${ theme.breakpoints.mobileLarge }) {
      font-size: 17px;
      line-height: 21px;
   }
`
