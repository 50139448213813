import styled from 'styled-components';

const colors = {
  veryLightPink: '#fdf5ed',
  beige: '#f1ebd6',
  black: '#333333',
  white: '#ffffff',
  heroWhite: '#fcf7f7',
  paleSalmon: '#ffa69e',
}

const themeColors = {
  background: colors.veryLightPink,
  lightHeroText: colors.black,
  lightHeroBackground: colors.beige,
  lightButtonHoverBackground: colors.paleSalmon,
  lightButtonHoverTextColor: colors.heroWhite,
  lightButtonBackground: colors.white,
  lightButtonTextColor: colors.black,
}

const fonts = {
  senExtraBold: '',
  senBold: 'Sen-Bold',
  senRegular: 'Sen-Regular'
}

const fontSize = {
  small: '10px',
  medium: '16px',
  large: '24px',
  extraLarge: '40px'
}

const breakpoints = {
  desktopLarge: '1590px',
  desktopReg: '1440px',
  desktopSmall: '1024px',
  tabletLarge: '900px',
  tabletReg: '800px',
  tabletSmall: '700px',
  mobileLarge: '600px',
  mobileReg: '420px',
  mobileSmall: '320px',
};

const components = {
  containers: {
    main: styled.div`
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      background: ${ themeColors.background };
    `,
    hero: styled.div`
      display: flex;
      flex-direction: row;
      background: ${ themeColors.lightHeroBackground };
      width: 100%;
      height: 540px;
    `,
  },
  titles: {
    hero: styled.div`
      font-family: ${ fonts.senBold };
      font-size: ${ fontSize.extraLarge };
      line-height: 49px;
      color: ${ themeColors.lightHeroText };
     `
  },
  descriptions: {
    hero:styled.div`
      font-family: ${ fonts.senRegular };
      font-size: ${ fontSize.medium };
      line-height: 19px;
      color: ${ themeColors.lightHeroText };
    `
  },
  buttons: {
    heroWideButton: styled.button`
      margin-top: 30px;
      font-family: ${ fonts.senBold };
      font-size: ${ fontSize.medium };
      width: 177px;
      line-height: 19px;
      color: ${ themeColors.lightButtonTextColor };
      background: ${ themeColors.lightButtonBackground };
      border-radius: 25px;
      height: 50px;
      text-transform: uppercase;
      border: 0;
      cursor: pointer;
      transition: ease-in-out 200ms;
      
      :hover {
        background: ${ themeColors.lightButtonHoverBackground };
        color: ${ themeColors.lightButtonHoverTextColor };
        border: 0;
      }
      
      :
    `
  }
}

export default {
  colors,
  components,
  fonts,
  fontSize,
  themeColors,
  breakpoints,
}
