import React from 'react';

import {
  Container,
  SectionLeft,
  SectionRight,
  Title,
  Button,
  Description,
  Image,
  ImageContainer
} from './styles';

/**
 * Hero Feature Component
 * @param title string
 * @param description string
 * @param buttonTitle string
 * @param buttonLink string
 * @param images string
 * @param reverse boolean
 * @returns {*}
 */
export default function heroBuyNow({
  title,
  description,
  buttonTitle,
  buttonLink,
  image,
  reverse,
                                   }) {
  return <Container>
      <SectionLeft>
          <Image image={ image }/>
      </SectionLeft>
      <SectionRight>
          <Title> {title} </Title>
          <Description> {description} </Description>
          <Button> {buttonTitle} </Button>
      </SectionRight>
  </Container>
}
