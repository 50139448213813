import React, { Component } from 'react';

import {
  Container
} from './styles';

import HeroBuyNow from '../ui/heroBuyNow';
import heroImage from '../../assets/images/test/shop-30@2x.jpg';

class Home extends Component {
  render() {
    return <Container>
        <HeroBuyNow
          image={ heroImage }
          title='One of the most popular items'
          description='Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod '
          buttonTitle='Buy Now'/>
    </Container>
  }
}

export default Home;
