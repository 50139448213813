import styled from 'styled-components';
import theme from '../../config/theme'

export const Container = styled(theme.components.containers.main)`
  position: relative;
`;

export const Main = styled.main`
  overflow: hidden;
`;
