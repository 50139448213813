import React, { PureComponent } from 'react';

import {
  Container,
  CenterContainer,
  Title,
  Description
} from './styles';

import Strings from '../../config/strings';

class ComingSoon extends PureComponent {
  render() {
    return <Container>
        <CenterContainer>
            <Title>
                { Strings.comingSoon }
            </Title>
            <Description>
                { Strings.motto }
            </Description>
        </CenterContainer>
    </Container>
  }
}

export default ComingSoon;
