import styled from 'styled-components';
import theme from '../../../config/theme';

export const Container = styled(theme.components.containers.hero)`
  display: flex;
  flex-direction: ${ (props) => props.reverse? 'row-reverse' : 'row' };

`

export const SectionRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-top: 146px;
  padding-bottom: 146px;
`

export const Title = styled(theme.components.titles.hero)`
  max-width: 400px;
`

export const Description = styled(theme.components.descriptions.hero)`
  max-width: 400px;
  margin-top: 30px;
`

export const Button = styled(theme.components.buttons.heroWideButton)`
 
`

export const SectionLeft = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.div`
    width: 430px;
    height: 475px;
    border-radius: 25px;
    background-size: 90% auto !important;
    background: ${ theme.colors.heroWhite } url("${ props => props.image }") no-repeat center center;
`
