/**
 * React
 */
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Stylized Components
 */
import { Container } from './styles';

/**
 * Import Components
 */
import Layout from './components/layout';
import Home from './components/home';
import ProductDetails from './components/productDetails';

class Routes extends Component {

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged();
    }
  }

  static propTypes = {
    location: PropTypes.object
  }

  onRouteChanged() {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }

  render() {
    return (
        <Container>
            <Layout>
                <Switch>
                    <Route path='/details' render={ (props) => <ProductDetails { ...props } /> }/>
                    <Route path='/' render={ (props) => <Home { ...props }/> }/>
                </Switch>
            </Layout>
        </Container>
    )
  }
}

function App(props) {
  return (
      <Routes { ...props } />);
}

export default withRouter(App);
