/**
 * React
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Stylized Components
 */
import {
  Container,
  Main
} from './styles';

import ComingSoon from '../comingsoon';

class Layout extends Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    children: PropTypes.object
  }

  render() {
    return (
        <Container>
            <Main>
                <ComingSoon/>
            </Main>
        </Container>
    )
  }
}

export default Layout;
